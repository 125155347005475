// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Cfforcategoryadditionfortemplates from "../../blocks/cfforcategoryadditionfortemplates/src/Cfforcategoryadditionfortemplates";
import ActivityFeed from "../../blocks/activityfeed/src/ActivityFeed";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Share from "../../blocks/share/src/Share";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Cfspacegeneration from "../../blocks/cfspacegeneration/src/Cfspacegeneration";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import AutomaticFormCreation from "../../blocks/automaticformcreation/src/AutomaticFormCreation";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Cfpreviewandcountofviewscountforfavalso from "../../blocks/cfpreviewandcountofviewscountforfavalso/src/Cfpreviewandcountofviewscountforfavalso";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfspaceediting from "../../blocks/cfspaceediting/src/Cfspaceediting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Cfrichtexteditor from "../../blocks/cfrichtexteditor/src/Cfrichtexteditor";
import ThemeBlock from "../../blocks/themeblock/src/ThemeBlock";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Cfphotoeditingfeatures from "../../blocks/cfphotoeditingfeatures/src/Cfphotoeditingfeatures";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Cfcustomanimation from "../../blocks/cfcustomanimation/src/Cfcustomanimation";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Videos from "../../blocks/videos/src/Videos";


const routeMap = {
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Cfforcategoryadditionfortemplates:{
 component:Cfforcategoryadditionfortemplates,
path:"/Cfforcategoryadditionfortemplates"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Share:{
 component:Share,
path:"/Share"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Cfspacegeneration:{
 component:Cfspacegeneration,
path:"/Cfspacegeneration"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
AutomaticFormCreation:{
 component:AutomaticFormCreation,
path:"/AutomaticFormCreation"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Cfpreviewandcountofviewscountforfavalso:{
 component:Cfpreviewandcountofviewscountforfavalso,
path:"/Cfpreviewandcountofviewscountforfavalso"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfspaceediting:{
 component:Cfspaceediting,
path:"/Cfspaceediting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Cfrichtexteditor:{
 component:Cfrichtexteditor,
path:"/Cfrichtexteditor"},
ThemeBlock:{
 component:ThemeBlock,
path:"/ThemeBlock"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Cfphotoeditingfeatures:{
 component:Cfphotoeditingfeatures,
path:"/Cfphotoeditingfeatures"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Cfcustomanimation:{
 component:Cfcustomanimation,
path:"/Cfcustomanimation"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Videos:{
 component:Videos,
path:"/Videos"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;